/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.test-class {
  background: #1F1651;
  min-width: 200px;
  min-height: 200px;
}
.heading1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.tmf-form_form-controls {
  display: flex;
  justify-content: flex-end;
}
.tmf-form_form-controls .ant-btn {
  padding: 4px 3rem;
  text-transform: uppercase;
}
.tmf-form_form-controls .ant-btn-link {
  color: #1F1651;
  padding: 4px 0.5rem;
  font-weight: 600;
}
.tmf-login-form {
  padding: 0 3rem;
}
.tmf-login-form h1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.tmf-login-form_alert {
  background-color: rgba(255, 20, 20, 0.15);
  border-color: rgba(255, 20, 20, 0.2);
}
.tmf-login-form_alert .ant-alert-icon {
  color: #ffa39e;
}
.tmf-login-form_alert .ant-alert-message {
  color: #1F1651;
}
.tmf-login-form_links {
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
  color: #1F1651;
  text-transform: uppercase;
}
.tmf-login-form_links a {
  color: #1F1651;
  font-weight: 600;
}
.tmf-login-form_controls {
  display: flex;
  justify-content: center;
  padding: 4vmin 2rem;
}
.tmf-login-form_controls .ant-btn {
  padding: 4px 3rem;
  text-transform: uppercase;
}
.tmf-login-form .hidden {
  visibility: hidden;
}
