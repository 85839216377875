th.assets-table_number, td.assets-table_number {
  text-align: right !important;
}

.assets-table_number {
  .anticon {
    margin-right: .1rem;

    path {
      fill: var(--primary);
    }
  }
}

.inv-details_asset-details-link {
  color: var(--current);
  text-decoration-color: var(--current);
  text-decoration-thickness: 0.075rem;
  text-underline-offset: 0.075rem;
  text-decoration-style: solid;
  text-decoration-line: underline;

  &:hover {
    color: #BD9A5F;
    text-decoration-color: #BD9A5F;
    text-decoration-thickness: 0.075rem;
    text-underline-offset: 0.075rem;
    text-decoration-style: solid;
    text-decoration-line: underline;
  }
}
