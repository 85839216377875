
.loan-details {
  h3 {
    margin: .5rem 0;
    text-align: left;
  }

  &_header {
    display: flex;
    justify-content: space-between;

    a {
      color: var(--current);
      text-decoration-color: var(--current);
      text-decoration-thickness: 0.075rem;
      text-underline-offset: 0.075rem;
      text-decoration-style: solid;
      text-decoration-line: underline;

      &:hover {
        color: #BD9A5F;
        text-decoration-color: #BD9A5F;
      }
    }
  }

  &_as-of-date {
    font-size: 1.7rem
  }

  &_loan-number {
    font-size: 2rem;
    margin-right: .5rem;
  }

  &_common-info {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';