/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.tmf-form-control {
  display: grid;
  row-gap: 0.2rem;
  grid-template-areas: "caption error error" "main main main";
  margin-bottom: 1rem;
}
.tmf-form-control_caption {
  grid-area: caption;
  text-transform: uppercase;
  color: #1F1651;
}
.tmf-form-control_error-message {
  grid-area: error;
  color: #ff4040;
  justify-self: end;
}
.tmf-form-control_error-message .anticon {
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}
.tmf-form-control_main {
  grid-area: main;
}
