@import '../../../styles/color.less';

.portal-layout {
  &_content {
    max-width: 70vw;
    margin: auto;
  }

  .header-wrapper {
    border-bottom: 1px solid @border-color-base;
  }

  &_header {
    min-height: 130px;
    display: grid;
    grid-template-areas:
      "logo user-profile user-profile"
      "logo navigation navigation";
    background-color: @secondary-color;
    padding: 0 15vw;

    &_logo {
      grid-area: logo;
      display: flex;
      align-items: center;

      .site-logo_wrapper {
        min-width: 250px;
      }
    }

    &_user-profile {
      grid-area: user-profile;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &_nav {
      grid-area: navigation;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &_profile-icon {
      cursor: pointer;
    }
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';