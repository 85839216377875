.admin-root {
  margin: auto;

  .site-logo_wrapper {
    min-width: 250px;
  }

  &_content {
    max-width: 70vw;
    margin: auto;
  }
}
