.rsrq-card label {
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  font-weight: 700;
  color: gray;
}

.rsrq-card p {
  font-size: 22px;
  font-weight: 100;
  margin-bottom: .1em;
}

.rsrq-control-panel h3,
.rsrq-card h3 {
  border-bottom: 1px dotted lightgray;
  padding: 5px 0;
  margin: .4rem .2rem;
  text-align: left;
  font-size: 24px;
  text-transform: uppercase;
  color: gray;
}