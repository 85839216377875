/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.profile-settings {
  min-width: 400px;
  margin: auto;
  width: 0.33vw;
  padding-top: 1rem;
}
.profile-settings .form-ctrl {
  margin: 0.6rem 0;
}
.profile-settings_controls {
  display: flex;
  justify-content: flex-end;
}
.profile-settings .tmf-form-control_caption {
  color: #000;
}
.auth-form__label {
  display: flex;
}
