.rrq__add-more {
  border-radius: 0.3rem;
  border: 1px dashed var(--lgray);
  margin: 0.2rem 0;

  .ant-btn {
    width: 100%;
    display: block;
  }
}

.resource-number {
  display: flex;

  &__input {
    margin: 0.2rem 0;

    .ant-btn {
      margin-right: -0.4rem;
    }
  }
}

.ant-modal-close-x {
  .anticon {
    vertical-align: -0.1rem;
  }
}
