.user-admin-profile .tmf-highlight {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 0.5rem 0.6rem;
  box-shadow: 1px 2px 6px lightgrey;

  &__content {
    align-self: flex-end;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 24px;
  }

  &__header-text {
    align-self: center;
    padding: 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: gray;
  }

  p {
    margin: 0;
    color: black;
    font-size: 1.5rem;
  }
}


@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';