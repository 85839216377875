.rsrq-details {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  &_spinner {
    margin: auto;
    padding: 20px;
    width: 100%;
    grid-column: 1/-1;
  }

  &-inner-spinner {
    margin: auto;
    padding: 10px;
    width: 100%;
  }

  &_user-info {

  }

  &_status-panel {
    padding: 0 .4rem;
  }

  &_request-panel {

  }
}

.rsrq-control-panel {
  .ant-card {
    margin-bottom: 2px;
  }

  &_buttons {
    display: flex;
    justify-content: space-between;
  }

  &_decline-text {
    margin-bottom: 1em;
  }
}

.rsrq-resolution_resource-card {
  width: 85%;
  margin: auto;
  text-align: left;
  border: 1px solid #D4D4D4;
  border-radius: 5px;
  padding: 7px 5px;

  h6 {
    text-transform: uppercase;
    color: #6c757d;
    font-weight: 600;
    font-size: 14px;
  }

  p {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 100;
  }
}

.rsrq-best-matches-list {
  h3 {
    border-bottom: 1px dotted lightgray;

    margin: .4rem .2rem;
    padding: 5px 2px;
    font-size: 24px;
    text-transform: uppercase;
    color: gray;
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 100;

    p {
      margin: 0;
    }

    li {
      padding: 2px;

      &:hover {
        background-color: #F8F8F8;
      }
    }

  }
}

.best-match-item {
  padding: 2px;
  border-left: 2px solid #6c757d;
  margin: 3px 0;

  h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #6c757d;
    margin: 0;
  }
}

.resource-card {
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #D4D4D4;

  p {
    margin: 0;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn {
      line-height: .5em;
    }
  }

  &--title {
    font-size: 22px;
    font-weight: 100;
  }
}
