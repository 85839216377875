/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.forgot-password-form {
  display: flex;
  flex-direction: column;
  color: #1F1651;
}
.forgot-password-form h1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.forgot-password-form_form-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgot-password-form_form-controls .ant-btn-link {
  color: #1F1651;
  font-weight: 600;
  text-transform: uppercase;
}
