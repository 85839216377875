.data-import {
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 0.5rem;

  &_uploader {
    display: block;
    margin: 0.8rem 0;
  }

  .ant-empty {
    margin-top: 10rem;
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';