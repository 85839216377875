.dis-progress {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 2rem;
  margin: 1.2rem 0;

  &__controls {
    display: flex;
    justify-content: flex-end;
    grid-column-end: span 2;

    .ant-btn {
      min-width: 6rem;
      margin-left: .5rem;
    }
  }

  &__status {
    grid-column-end: span 2;
    display: flex;

    p {
      font-size: 12px;
      color: gray;
    }
  }

  .data-import-job {
    &__header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: .5rem;

      &__subtitle {
        color: lightgrey;
      }
    }

    h4 {
      margin-bottom: .1rem;
    }

    &__subjob {
      padding-left: 2rem;
    }
  }
}
