.investment-details {
  h3 {
    margin: .5rem 0;
    text-align: left;
    font-size: 1.5rem;
  }

  &_container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;

    .statements-table {
      h3 {
        margin-bottom: 0;
      }
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;

    a {
      color: var(--current);
      text-decoration-color: var(--current);
      text-decoration-thickness: 0.075rem;
      text-underline-offset: 0.075rem;
      text-decoration-style: solid;
      text-decoration-line: underline;

      &:hover {
        color: #BD9A5F;
        text-decoration-color: #BD9A5F;
      }
    }
  }

  &_number {
    font-size: 2rem;
    margin-right: .5rem;
  }

  &_summary {
    display: grid;
    margin-top: 1rem;
    grid-template-columns: 1fr 2fr;
  }

  &_activities {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: .5rem;
    margin-top: 1.5rem;
  }

  .account-statements-table {
    &_header {
      display: flex;
      justify-content: space-between;

      .anticon {
        vertical-align: .075rem;
      }
    }

    &_status-column {
      .anticon {
        vertical-align: .075rem;
      }
    }
  }
}

.statements-download-instructions {
  padding-left: 1rem;

  li {
    padding: .3rem 0;
  }

  .link-like-text {
    color: var(--primary);
  }

  .ant-tag {
    margin-right: 0;
  }

  &_root {
    max-width: 450px;
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';