@import '../../styles/variables';

.admin__dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  padding-top: 1rem;
}

@media only screen and (min-width: 1600px) {
  .admin__dashboard {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
