/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.confirm-email-form {
  color: #1F1651;
}
.confirm-email-form h1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.confirm-email-form h2 {
  color: #1F1651;
}
.confirm-email-form_spinner {
  width: 100%;
  margin: 0 auto;
}
.confirm-email-form_controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-email-form_controls .ant-btn-link {
  color: #1F1651;
  text-transform: uppercase;
  font-weight: 600;
}
