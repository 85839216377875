/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.user-heading_data {
  margin-left: 0.6rem;
  background: #1F1651;
  padding: 0.2rem 0.4rem;
}
.user-heading_data h2 {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  color: #fff;
}
