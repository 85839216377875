:root {
  --lgray: #d4d4d4;
  --accentcolor: #333333;
  --lblue: #abd0ff;
  --done: #a1e4a8;
  --current: #f9c14f;
  --default: #ecedee;
}

body {
  overflow: overlay;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
}
