.acc-activity-table {
  margin-bottom: 2rem;
  
  .anticon {
    vertical-align: .075rem;
  }

  th.acc-activity-table_number-cell,
  td.acc-activity-table_number-cell {
    text-align: right !important;
  }
}