th.asset-statements-table_number-cell,
td.asset-statements-table_number-cell {
  text-align: right !important;
}

.asset-statements-table_root {
  &--no-one-page {
    margin-top: 0.5rem;
  }

  .anticon {
    vertical-align: .075rem;
  }
}
