/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.site-logo_wrapper {
  display: flex;
  justify-content: center;
}
.site-logo_wrapper img {
  max-width: 300px;
}
.site-logo {
  width: 220px;
}
.site-logo .cls-1 {
  fill: #fff;
}
