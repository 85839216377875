.rrq-list__item {
  &-date__rel-date {
    color: var(--lgray);
    margin-left: .5rem;
  }
}

.rejected-rrq_comment {
  margin-left: -38px;
}

.rejected-rrq_modal_description {
  margin: 0;
  margin-left: -38px;
}

.rrq-admin-alert {
  margin-bottom: 1.5rem;
}

.rrq-table {
  .ant-pagination .anticon {
    vertical-align: .075rem;
  }
}

.rrq-table-description {
  text-align: right;
  color: var(--lgray);
  font-style: italic;
  margin-bottom: .1rem;
}

.user-profile {
  &_as-of-date {
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 100;
  }
}