.rsrq-view {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.rsrq-view--empty {
  margin: 150px;
}

.rsrq-list {
  list-style: none;
  padding: 0;
}

.rsrq-view .anticon {
  vertical-align: 0.15em;
}

.rsrq-list--item {
  display: flex;
  justify-content: space-between;
  border-left: 3px solid lightgray;
  border-bottom: 1px dotted lightgray;
  padding: 2px 5px 6px 5px;
  margin: 1px 0px;
}

.rsrq-list--item:hover {
  background-color: #f8f8f8;
}

.rsrq-list--item--open {
  border-left-color: #ffc107;
}

.rsrq-list--item--accepted {
  border-left-color: #17a2b8;
}

.rsrq-list--item--declined {
  border-left-color: #dc3545;
}

.rsrq-list--item--approved {
  border-left-color: #28a745;
}

.rsrq-list--item-header {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 100;
}

.rsrq-list--item-subheader {
  font-size: 12px;
  color: gray;
}

.rsrq-list--item-lastUpdatedHeader {
  text-align: right;
  font-size: 12px;
}

.rsrq-list--item-lastUpdatedSubheader {
  font-size: 12px;
  text-align: right;
  color: lightgray;
}

.rsrq-list--item-mainSection {
  flex-grow: 1;
  margin: 0px 10px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}

.rsrq-list--item-info {
  min-width: 250px;
}

.rsrq-list--item-mainSection h3,
.rsrq-list--item-mainSection h4 {
  text-transform: uppercase;
  color: lightgray;
  font-size: 12px;
}

.rsrq-list--item-mainSectionItem .requested-number {
  overflow: hidden;
  white-space: nowrap;
}

.rsrq-list--item-mainSectionItem .initiated-ref,
.rsrq-list--item-mainSectionItem .status {
  flex-grow: 1;
}

.initiated-ref {
  flex-grow: 2;
}

.status {
  flex-grow: 2;
}

.requested-number {
  flex-grow: 8;
}

.rrq-list {
  padding: 0.8rem 0;

  &--item-mainSection strong {
    text-transform: none;
    color: rgb(0, 0, 0);
  }

  &--item-mainSection p {
    margin: 0;
    line-height: 22px;
  }
}

.rrq-list_item {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 1.5fr;
  border-left: 2px solid var(--lgray);
  border-bottom: 1px dotted var(--lgray);
  margin-bottom: 0.2rem;
  padding: 0.5rem 0;
  overflow: hidden;

  $color-approved: #28a745;
  $color-declined: #dc3545;
  $color-open: #ffc107;
  $color-accepted: #17a2b8;

  &:hover {
    background-color: #f8f8f8;
  }

  &--open {
    border-left-color: $color-open;
  }

  &_tag--open {
    background-color: lighten($color-open, 50%);
    color: $color-open;
    border-color: lighten($color-open, 20%);
  }

  &--accepted {
    border-left-color: $color-accepted;
  }

  &_tag--accepted {
    background-color: lighten($color-accepted, 50%);
    color: $color-accepted;
    border-color: lighten($color-accepted, 20%);
  }

  &--approved {
    border-left-color: $color-approved;
  }

  &_tag--approved {
    background-color: lighten($color-approved, 50%);
    color: $color-approved;
    border-color: lighten($color-approved, 20%);
  }

  &--declined {
    border-left-color: $color-declined;
  }

  &_tag--declined {
    background-color: lighten($color-declined, 45%);
    color: $color-declined;
    border-color: lighten($color-declined, 20%);
  }

  &_secondary-text {
    font-size: 12px;
    color: gray;
  }

  &_number-col {
    margin-left: 0.5rem;
    h3 {
      margin: 0;
      text-align: left;
      font-weight: 400;
      font-size: 1.3rem;
      color: var(--secondary);

      span {
        font-weight: 300;
      }
    }
  }

  &_col {
    &:nth-last-child(2) {
      text-align: right;
      padding-right: 0.5rem;
      grid-column: 5;
      grid-row: 1;
    }

    h4 {
      margin: 0;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: var(--lgray);
    }

    .ant-tag {
      text-transform: uppercase;
      margin-top: 0.2rem;
    }
  }

  & .controls {
    display: none;
  }
}

.rsrq-list--mine {
  .rrq-list_item {
    & .controls {
      grid-column: 5;
      grid-row: 1;
      background-color: var(--dark);
      opacity: 0;
      margin: -0.5rem 0 -0.5rem 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .ant-btn {
        align-self: center;
      }
    }

    &:hover .controls {
      animation: 0.4s ease-out 0s 1 normal both show_delete;
    }
  }
}

.float-right {
  color: lightgray;
}

@keyframes show_delete {
  0% {
    margin-left: 100%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    opacity: 0.9;
  }
}
