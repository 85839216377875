/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header-wrapper {
  background-color: #FFFFFF;
  border-bottom: 1px solid #BD9A5F;
}
.main-heading {
  max-width: 70vw;
  display: grid;
  min-height: 130px;
  grid-template-areas: "logo user-menu" "logo navigation";
  grid-template-columns: 3fr 7fr;
  margin: auto;
}
.main-heading_home-link {
  grid-area: logo;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.main-heading_credits {
  grid-area: logo;
  display: flex;
  align-items: center;
}
.main-heading_credits h1,
.main-heading_credits h2,
.main-heading_credits span,
.main-heading_credits a,
.main-heading_credits img,
.main-heading_credits li {
  margin: 0;
  padding: 0;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
.main-heading_credits h1 {
  font-size: 1.8rem;
  margin: 0.5rem 0;
}
.main-heading_credits h2 {
  font-size: 1.2rem;
  padding: 0.2rem 0.4rem;
  background: #1F1651;
  color: #fff;
  margin-left: 0.6rem;
}
.main-heading_nav {
  grid-area: navigation;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.main-heading_menu {
  list-style: none;
  margin: 0;
  display: flex;
  padding: 0.3rem 0.3rem 0;
  justify-content: flex-end;
}
.main-heading_menu li {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-bottom: 2px solid #FFFFFF;
}
.main-heading_menu li:hover {
  border-bottom-color: #BD9A5F;
  color: #fff;
}
.main-heading_menu-item {
  font-size: 1rem;
  font-weight: 400;
  color: #1F1651;
  padding: 0.3rem 2rem;
}
.main-heading_menu-item:hover {
  color: #BD9A5F;
}
.main-heading_menu-item--active {
  font-weight: 700;
}
.main-heading_account {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.main-heading_profile {
  grid-area: user-menu;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.main-heading_profile-as-of-date {
  margin: 0 1rem 0.3rem 0;
  text-transform: uppercase;
  color: #1F1651;
  font-size: 1.2rem;
}
.main-heading_profile-icon {
  cursor: pointer;
}
