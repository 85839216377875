@import '../../../styles/color.less';

.auth-root {
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 3fr 1fr;
  background-color: @secondary-color;

  &__logo {
    padding: 10vmin 0;
  }

  &__content {
    padding: 2rem 3rem;
    overflow: auto;
  }

  &__image {
    width: 100%;
    min-height: 100vh;
    object-fit: cover;
    object-position: left;
    user-select: none;
    pointer-events: none;
  }

  .forgot-password-form_form-controls {
    button {
      margin-top: .3rem;
    }
  }
}

@media (max-width: 1450px) {
  .auth-root {
    grid-template-columns: 3fr 2fr;

    &__content {
      padding: 2rem 3rem;
    }
  }
}

@media (max-width: 1024px) {
  .auth-root__content {
    padding: 3rem 3rem;
  }
}

@media (max-width: 780px) {
  .auth-root {
    grid-template-columns: 1fr;
    overflow: auto;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 5rem 10rem;
    }

    &__logo {
      padding: 5vmin 0;
    }

    &__wrapper {
      display: none;
    }

    .site-logo {
      width: 350px
    }

    .tmf-form_form-controls {
      margin-top: 1rem;
      justify-content: space-between;
    }
  }
}

@media (max-width: 670px) {
  .auth-root__content {
    padding: 3rem 4rem;
  }
}

@media (max-width: 420px) {
  .auth-root {
    grid-template-columns: 1fr;

    .site-logo {
      width: 270px
    }

    &__content {
      padding: 2rem 2rem;
    }

    .tmf-form-control {
      margin-bottom: 0.5rem;
    }

    .tmf-login-form_links {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 375px) {
  .auth-root__content {
    padding: 1rem 1.5rem;

    .site-logo {
      width: 220px
    }
  }
}

@media (max-width: 325px) {
  .auth-root__content {
    padding: 1rem;
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';