.vault_investments-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  &_item {
    border: 1px solid var(--lgray);
    border-radius: .4rem;
    margin: .8rem 0;
    padding: 1.8rem 1rem;

    .anticon {
      vertical-align: .075rem;
    }
  }
}

.vault_how-to {
  max-width: 750px;
  margin: auto;
}