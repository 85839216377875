.users-view_filtering {
  padding: 0.8rem 0;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.city-filter-panel {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: end;

  &_ctrl {
    display: flex;
    flex-direction: column;
    margin: 0 0.2rem;
  }
}

.users-view_list-header {
  margin: 0.6rem 0;
  display: flex;
  justify-content: space-between;
}

.user-list {
  margin-bottom: 1rem;

  &_inline-button {
    padding: 0;
    height: 20px;
  }

  &_email {
    .anticon {
      margin-left: 0.2rem;
      color: --primary;
    }
  }

  &_info-icon {
    color: var(--primary);
    margin-left: 0.2rem;
    vertical-align: 0;
  }
}
