/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.view-selector .selected {
  color: #1F1651;
  border-bottom-color: #1F1651;
}
.view-selector--item {
  user-select: none;
  padding: 0.3rem 1rem;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 2px solid #fff;
}
.view-selector--item:hover {
  color: #1F1651;
}
.view-selector--item:active {
  color: #3e2ca1;
}
.rsrq-list--item-mainSectionItem {
  flex-grow: 1;
}
.rsrq-list--item-mainSectionItemRef {
  flex-grow: 2;
  font-size: 12px;
}
