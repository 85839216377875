.tmf-dashboard-card {
  border-radius: 6px;
  padding: 0.5rem 0.6rem;
  box-shadow: 1px 2px 6px lightgrey;

  h2 {
    padding: 0.5rem;
    text-align: left;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  &__content {
    padding: 0.5rem;
    .ant-alert {
      margin: 0.2rem 0;
    }
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';