/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.loans-list_item {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 1.8rem 1rem;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  margin: 0.8rem 0;
}
.loans-list_item-header-container h4 {
  font-weight: 100;
  font-size: 1.5rem;
  color: #1F1651;
}
.loans-list_item-header-container span {
  font-weight: 100;
  font-size: 2rem;
  padding-right: 0.5rem;
}
.loans-list_item-header-container .number {
  color: var(--current);
  text-decoration-color: var(--current);
  text-decoration-thickness: 0.075rem;
  text-underline-offset: 0.075rem;
  text-decoration-style: solid;
  text-decoration-line: underline;
}
.loans-list_item-header-container .number:hover {
  color: #BD9A5F;
  text-decoration-color: #BD9A5F;
}
.loans-list_item--condensed {
  border: 1px dashed lightgray;
}
.loans-list_item--condensed .ant-result {
  grid-row: 1 / span 2;
}
.loans-list_item-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
