@import '~antd/lib/style/themes/default.less';

.test-class {
    background: @primary-color;
    min-width: 200px;
    min-height: 200px;
}

.heading1 {
  color: @primary-color;
  text-align: center;
  text-transform: uppercase;
}

.tmf-form {
  &_form-controls {
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      padding: 4px 3rem;
      text-transform: uppercase;
    }

    .ant-btn-link {
      color: @primary-color;
      padding: 4px 0.5rem;
      font-weight: 600;
    }
  }
}
