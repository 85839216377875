/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.navigation_menu {
  list-style: none;
  margin: 0;
  display: flex;
  padding: 0.3rem 0.3rem 0;
  justify-content: flex-end;
}
.navigation_menu li {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-bottom: 2px solid #FFFFFF;
}
.navigation_menu li:hover {
  border-bottom-color: #BD9A5F;
}
.navigation_menu-item {
  font-size: 1rem;
  font-weight: 400;
  color: #1F1651;
  padding: 0.3rem 2rem;
}
.navigation_menu-item:hover {
  color: #BD9A5F;
}
.navigation_menu-item--active {
  font-weight: 700;
}
