.data-import-sessions {
  &__root {
    h2 {
      text-align: left;
      padding-left: 0;
      font-size: 2rem;
    }
  }

  &__list-item {
    border-bottom: 1px dotted lightgray;
    border-left: 3px solid lightgray;
    padding: 0 .3rem;
    user-select: none;

    &--success {
      border-left-color: #28a745;
    }

    &--inprogress {
      border-left-color: #17a2b8;
    }

    &--failed {
      border-left-color: #dc3545;
    }

    &--selected {
      background-color: lightgrey;
    }

    h3 {
      margin: 0;
      text-align: left;
    }
  }

  &__list-item-header {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: baseline;

    p {
      text-align: right;
    }
  }

  &__list-item-description {
    p {
      text-align: right;
      font-size: 12px;
      color: gray;
    }

    &--success {
      p {
        color: #28a745
      }
    }
  }
}