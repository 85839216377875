.user-details {
  
}

.user-details h3 {
  margin: .4rem .2rem;
  text-align: left;
  border-bottom: 1px dotted lightgray;
  padding: 5px 2px;
  font-size: 24px;
  text-transform: uppercase;
  color: gray;
}

.user-details label {
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  font-weight: 700;
  color: gray;
}

.user-details p {
  font-size: 22px;
  font-weight: 100;
  margin-bottom: .1em;
}

.user-details address {
  margin-bottom: .5em;
}

.user-details-list p {
  font-size: 12px;
  font-weight: normal;
}

.user-details-list strong {
  font-weight: 700;
  margin-right: 3px;
}