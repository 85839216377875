.user-admin-profile {
  display: grid;
  grid-template-columns: auto 3fr 4fr;
  column-gap: 1rem;
  grid-template-rows: 5rem auto auto;
  grid-template-areas:
    "header header header"
    "loginhist highlights highlights"
    "loginhist data rsrqs";

  h2, h3 {
    text-align: left;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  h3 {
    padding: 0 0 .8rem 0;
    margin: 0;
    font-size: 1.5rem;
  }

  &__wrapper-highlights {
    grid-area: highlights;
  }

  &__highlights {
    display: flex;
    flex-wrap: wrap;
    column-gap: .4rem;
    row-gap: .5rem;

    .item {
      flex: 1 1 auto;
    }
  }

  &__header {
    grid-area: header;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;

    .right-stack-panel {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  &__login-history {
    grid-area: loginhist;
    height: 0;
    min-height: 100%;
  }

  &__resource-requests {
    grid-area: rsrqs;
    padding-top: 1rem;

    .request-list-item {
      display: grid;
      grid-template-columns: 3fr 4fr 1fr 1fr;
      align-items: baseline;
      justify-items: left;

      &__heading {
        display: grid;
        grid-template-columns: 3fr 4fr 1fr 1fr;
        justify-items: left;
        margin-bottom: 0.4rem;
        border-bottom: 1px dotted lightgrey;

        span {
          font-size: .8rem;
          text-transform: uppercase;
          color: lightgrey;
        }
      }

      h4 {
        font-size: 1.1rem;
      }

      &:hover .basic-list-item__controls {
        opacity: 1;
      }

      & .basic-list-item__controls {
        opacity: .4;
        justify-self: end;
      }
    }
  }

  &__data {
    grid-area: data;
    padding-top: 1rem;
  }

  .basic-list-item {
    display: flex;
    justify-content: space-between;

    h4 {
      font-size: 1.1rem;
    }

    &:hover .basic-list-item__controls {
      opacity: 1;
    }

    & .basic-list-item__controls {
      opacity: .2;
    }
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';