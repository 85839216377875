.asset-details-page {
  h3 {
    margin: .5rem 0;
    text-align: left;
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &_header {
    display: flex;
    justify-content: space-between;

    a {
      color: var(--current);
      text-decoration-color: var(--current);
      text-decoration-thickness: 0.075rem;
      text-underline-offset: 0.075rem;
      text-decoration-style: solid;
      text-decoration-line: underline;
    }
  }

  &_number {
    font-size: 2rem;
    margin-right: .5rem;
  }

  &_description {
    margin-left: .4rem;
  }

  &_content {
    h5 {
      margin: 2rem 0;
    }
  }
}

@primary-color: #1F1651;@sucess-color: #FFFFFF;@border-color-base: #BD9A5F;@input-hover-border-color: color(~`colorPalette("@{@border-color-base}", 7) `);@font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';