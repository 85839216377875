@import '../../styles/variables';

.investment-list {
  &_item {
    border: 1px solid var(--lgray);
    border-radius: .4rem;
    margin: .8rem 0;
    padding: 1.8rem 1rem;

    & .asset-link {
      text-decoration-color: var(--current);
      color: var(--current);
      text-decoration-thickness: 0.075rem;
      text-underline-offset: 0.075rem;
      text-decoration-style: solid;
      text-decoration-line: underline;

      &:hover {
        color: #BD9A5F;
        text-decoration-color: #BD9A5F;
      }
    }

    &--lastone {
      border: 1px dashed var(--lgray);
    }
  }

  &_item-header {
    font-weight: 100;
    font-size: 1.5rem;
    color: var(--primary);

    span.link {
      font-weight: 100;
      font-size: 2rem;
      padding-right: .5rem;
      color: var(--current);
      text-decoration-color: var(--current);
      text-decoration-thickness: 0.125rem;
      text-underline-offset: 0.125rem;
      text-decoration-style: solid;
      text-decoration-line: underline;

      &:hover {
        color: #BD9A5F;
        text-decoration-color: #BD9A5F;
      }
    }
  }

  &_asset-number-cell {
    text-align: right !important;
    font-variant-numeric: tabular-nums;
  }
}
