/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.test-class {
  background: #1F1651;
  min-width: 200px;
  min-height: 200px;
}
.heading1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.tmf-form_form-controls {
  display: flex;
  justify-content: flex-end;
}
.tmf-form_form-controls .ant-btn {
  padding: 4px 3rem;
  text-transform: uppercase;
}
.tmf-form_form-controls .ant-btn-link {
  color: #1F1651;
  padding: 4px 0.5rem;
  font-weight: 600;
}
.city-option_sub {
  color: rgba(0, 0, 0, 0.45);
}
.tmf-reg-form h1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.tmf-reg-form_result {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tmf-reg-form_result h1 {
  color: #1F1651;
  text-align: center;
  text-transform: uppercase;
}
.tmf-reg-form_result p {
  color: #1F1651;
}
.tmf-reg-form_result .ant-btn-link {
  color: #1F1651;
  padding: 4px 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
