.dis-wizard {
  padding: 0 1rem;

  &__uploader {
    &.ant-upload {
      height: auto;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      text-align: left;
      padding-left: 0;
      font-size: 2rem;
    }
  }
}

.dis-wizard-step {
  padding: 2rem 0;

  &__content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: .5rem;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-btn {
      min-width: 6rem;
      margin-left: .5rem;
    }

    .legend {
      display: flex;
      align-items: center;

      p {
        margin: 0 1rem 0 .2rem;
        color: gray;
      }
    }
  }

  .file-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4, h5 {
      text-align: center;
    }

    h4 {
      margin: .4em;
      font-size: 1.2rem;
    }

    h5 {
      font-size: 1rem;
      margin: .2em;
    }

    p {
      color: gray;
      font-size: .8rem;
    }
  }

  &__upload-files {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1rem;
  }
}
